import { useShopId } from "hooks/localstate/context/useShopId"
import { generateBasketKey } from "hooks/localstate/localstorage/useBasketId"
import { setLocalStorage } from "hooks/localstate/localstorage/useLocalStorage"
import { useRouter } from "next/router"
import { useEffect } from "react"

const useBasketIdFromURL = () => {
  const router = useRouter()
  return router.query.basketId as string | undefined
}

export default function URLBasketIdLoader() {
  const basketId = useBasketIdFromURL()
  const shopId = useShopId()

  useEffect(() => {
    if (basketId && shopId) {
      setLocalStorage(generateBasketKey(shopId), basketId)
    }
  }, [basketId, shopId])

  return <></>
}

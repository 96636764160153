import React, { createContext, useContext, useState } from "react"

interface OrderLoadingModalContextType {
  showOrderLoadingModal: boolean
  setShowOrderLoadingModal: (show: boolean) => void
}

const OrderLoadingModalContext = createContext<OrderLoadingModalContextType | undefined>(undefined)

export const OrderLoadingModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [showOrderLoadingModal, setShowOrderLoadingModal] = useState(false)

  return (
    <OrderLoadingModalContext.Provider value={{ showOrderLoadingModal, setShowOrderLoadingModal }}>
      {children}
    </OrderLoadingModalContext.Provider>
  )
}

export const useOrderLoadingModal = () => {
  const context = useContext(OrderLoadingModalContext)
  if (context === undefined) {
    throw new Error("useOrderLoadingModal must be used within an OrderLoadingModalProvider")
  }
  return context
}

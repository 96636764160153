import { Loader } from "components/LEGACY/styled-components/Icons"
import { useOrderLoadingModal } from "context/orderFormContexts/OrderLoadingModalContext"
import React from "react"

const OrderLoadingModal: React.FC = () => {
  const { showOrderLoadingModal } = useOrderLoadingModal()

  if (!showOrderLoadingModal) return null

  return (
    <div className="order-loading-modal">
      <div className="modal-content">
        <Loader size="48" color="primary" />
        <p>Wir empfangen deine Bestellung. Kleinen Moment bitte...</p>
      </div>
      <style jsx>{`
        .order-loading-modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modal-content {
          background-color: white;
          padding: 2rem;
          border-radius: 8px;
          text-align: center;
        }
        p {
          margin-top: 1rem;
          font-size: 1.2rem;
        }
      `}</style>
    </div>
  )
}

export default OrderLoadingModal

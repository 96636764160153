import { InfoCircle } from "components/LEGACY/styled-components/Icons"
import HeaderLogo from "components/orderPage/HeaderLogo"
import MyOrdersButton from "components/orderPage/MyOrdersButton"
import SwitchBetweenB2BAndNormalModeButton from "components/orderPage/SwitchBetweenB2BAndNormalModeButton"
import { useShop } from "hooks/firestore/simple/useShop"
import dynamic from "next/dynamic"
import React, { useState } from "react"
import { Envelope } from "styled-icons/fa-solid"
import Search from "./Search/Search"
import BasketButton from "./StickyBasket/BasketButton"

const InfoModal = dynamic(() => import("components/orderPage/InfoModal"), { ssr: false })
const NewsletterModal = dynamic(() => import("components/orderPage/NewsletterModal"), { ssr: false })

function ShopHeader() {
  const [isInfoView, setInfoView] = useState(false)
  const [isNewsletterView, setNewsletterView] = useState(false)
  const shop = useShop()

  return shop ? (
    <>
      <style jsx>{`
        .appHeader {
          width: 100%;
          display: flex;
          flex-direction: column;
          background: var(--gradient);
          border-radius: 0 0 0 30px;
          padding: calc(env(safe-area-inset-top, 20px) + 30px) var(--shopSpace) 74px;
          margin-bottom: -2.5rem;
          gap: 15px;
          color: var(--white);
        }

        .shopTitleRow {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .shopLogoContainer {
          border-radius: 15px;
          padding: 2px;
          background: currentColor;
          display: flex;
        }
        .shopLogoContainer > :global(span) {
          border-radius: 13px;
        }

        .shopHeaderButton {
          margin-bottom: 0;
          padding: 0;
          border: none;
          background: none;
          outline: none;
          font-family: inherit;
          display: flex;
          align-items: center;
          flex-grow: 1;
          gap: 10px;
        }

        .shopHeaderButton:focus {
          outline: none;
        }

        .shopTitle {
          text-align: left;
          margin: 0;
          color: currentColor;
          font-size: 1.3rem;
        }

        .shopDescription {
          font-size: 1rem;
          line-height: 1.3;
          margin-bottom: 0;
        }
      `}</style>
      {isInfoView ? <InfoModal open={isInfoView} onClose={() => setInfoView(false)} /> : null}
      {isNewsletterView ? <NewsletterModal open={isNewsletterView} onClose={() => setNewsletterView(false)} /> : null}
      <div className="appHeader">
        <div className="shopTitleRow" data-nosnippet>
          <HeaderLogo shop={shop} onLogoClick={() => setInfoView(true)} />

          <button className="shopHeaderButton" onClick={() => setInfoView(true)}>
            <h1 className="shopTitle">{shop.name}</h1>
            <InfoCircle color={"currentColor"} size={21} style={{ flexShrink: 0 }} />
          </button>

          <BasketButton useExpand={false} white />
        </div>
        <SwitchBetweenB2BAndNormalModeButton />
        <MyOrdersButton />
        {shop.newsletterSignup?.active && (
          <button
            onClick={() => setNewsletterView(true)}
            className="tw-relative tw-flex tw-items-center tw-justify-center tw-border-none tw-px-[10px] tw-bg-white tw-pl-[20px] tw-text-uppercase tw-m-0 tw-text-[1rem] tw-font-bold tw-outline-none tw-flex-shrink-0 tw-min-w-[50px] tw-min-h-[50px] tw-h-[50px] tw-rounded-[10px] tw-text-[var(--primary)] disabled:tw-opacity-30"
          >
            {/* SVG Icon */}
            <Envelope className="tw-w-4 tw-h-4 tw-text-[var(--primary)]" />

            {/* Text */}
            <span className="tw-ml-[10px] tw-pr-[10px] tw-text-[1rem] tw-font-bold">Newsletter abonnieren</span>
          </button>
        )}
        {shop.customShopNotification && <p className="shopDescription">{shop.customShopNotification}</p>}
        <Search />
      </div>
    </>
  ) : null
}

export default React.memo(ShopHeader)

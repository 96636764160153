import ProductGroupCategory from "components/orderPage/CategoryDisplay/ProductGroupCategory"
import SubcategoryCategory from "components/orderPage/CategoryDisplay/SubcategoryCategory"
import { useCategory } from "hooks/firestore/simple/useCategory"
import { useShop } from "hooks/firestore/simple/useShop"
import { useStorefrontLocation } from "hooks/localstate/url/useStorefrontLocation"
import last from "lodash/last"
import React from "react"

const CategoryDisplay = () => {
  const shop = useShop()
  const { location } = useStorefrontLocation()
  const categoryId = last(location.categoryIds) || ""
  const category = useCategory(categoryId || undefined)

  const categoriesOnlyHomepage = shop?.categoriesOnlyHomepage && !location.categoryIds?.length

  return categoryId === "" && categoriesOnlyHomepage ? null : category?.productGroups?.length ||
    category?.bundles?.length ? (
    <ProductGroupCategory categoryId={categoryId} />
  ) : (
    <SubcategoryCategory categoryId={categoryId} />
  )
}

export default React.memo(CategoryDisplay)
